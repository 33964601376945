import Home from "theme/pages/Home";

export default Home;

/**
 * To enable cache control for home page (or any static page for that matters) define a static property `cacheControlDefinition` on the Component itself. This property should define proper `sMaxAge` and `staleWhileRevalidate` values
 * Also make sure the statics of the component are hoisted all the way up so that this property is available when imported by other modules
 */
/**
 * source: https://docs.google.com/document/d/1w1hdSZ3TE2x2JhCyYnQ4HOLLnhXWIlqzR2iRqvbFX_o/edit
 * hors catégorie et page produit : s-max-age : 1mn / stale-while-revalidate : 1h
 */
Home.cacheControlDefinition = {
  sMaxAge: 60,
  staleWhileRevalidate: 3600,
};
